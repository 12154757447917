import React, { Component } from 'react';
import ReactPlayer from 'react-player/lazy';
import Narration from './Narration.js';
import jsonator from '../utils/convertDataToJSON.js';
import { transcript } from '../transcript';
import getStartTimes from '../utils/getStartTimes.js'
import { Link } from 'react-router-dom';
import rightArrow from '../img/right-arrow.svg';

class VideoGamesForLearning extends Component {

    state = {
        narrationText: [],
        startTimes: []
    }

    componentDidMount() {
        this.convertData();
    }

    convertData = () => {
        this.setState({
          narrationText: jsonator(transcript),
          startTimes: getStartTimes(transcript)
        })
    }

    render() {
        return(
            <div>
                <Narration data={this.state.narrationText} startTimes={this.state.startTimes}/>
            </div>
        )
    }
}

export default VideoGamesForLearning;