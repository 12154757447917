import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Callout extends Component {
    
    render() {
        const { text } = this.props;
        return(
            <div className="callout-container">
                <p>In the case of the Moderna vaccine paper, think about all the things you need to understand to deeply understand what's happening when you're injected:</p>
                <ul>
                    <li><p>Antibodies and immune system response</p></li>
                    <li><p>Sample size, statistical power and statistical error</p></li>
                    <li><p>Clinical trials and process of bringing new therapeutics to market</p></li>
                    <li><p>(mRNA and related pathways, though the specific mode of action is probably less important in this case)</p></li>
                </ul>
                <p>These ideas are not covered in the paper, because they are assumed of the reader. There is no affordance provided for learning about them, either. Unfortunately, most people don't understand these ideas. Realistically, therefore, they don't stand a chance of understanding this paper, and therefore cannot deeply understand the Moderna vaccine or its implications (good or bad).</p>
            </div>
        )
    }
}

export default Callout;