import React, { Component, createRef } from 'react';
import NarrationParagraph from './NarrationParagraph.js';
import ReactPlayer from 'react-player/lazy';
import video from '../video/emoji-movie.mp4'
import playIcon from '../img/play-icon.svg';
import pauseIcon from '../img/pause-icon.svg';
import rightArrow from '../img/right-arrow.svg';
import { Link } from 'react-router-dom';

class Narration extends Component {
    
    state = {
        playing: false,
        hasPlayed: false,
        hasEnded: false,
        played: 0,
        playedPercentage: 0,
        seeking: false,
        currentWordID: 0
    }

    componentDidUpdate() {
        const { played, currentWordID } = this.state;
        const { startTimes } = this.props;
        if (played >= startTimes[currentWordID]) {
            this.setState({
                currentWordID: currentWordID + 1
            });
        };
        console.log(currentWordID)
        console.log(played)
    }
    
    narrationParagraphRef: { current: any } = createRef();
    
    handlePauseClick = () => {
        this.setState({
            isPlaying: false
        });
        this.narrationParagraphRef.current.pauseNarration()
    }

    handlePlayClick = () => {
        const { hasPlayed, isPlaying, hasEnded, seeking } = this.state;

        // If not currently playing, has not played ever and has not ended, play the audio from start.
        if (!isPlaying && !hasPlayed && !hasEnded) {
            this.setState({
                hasPlayed: true,
                playing: true
            });
            this.narrationParagraphRef.current.startNarration()
        }

        // If not currently playing and has not ended, but has played, resume the audio.
        if (!isPlaying && hasPlayed && !hasEnded) {
            this.setState({
                playing: true
            });
            this.narrationParagraphRef.current.resumeNarration()
        }

        if (seeking) {
            this.setState({
                playing: true
            })
        }

        // If playback has finished, replay the audio and reset values.
        if (hasEnded) {
            this.setState({
                hasEnded: false
            });
            this.narrationParagraphRef.current.resetNarration()
        }
    }

    handleEnded = () => {
        this.setState({
            isPlaying: false,
            hasEnded: true
        });
        this.narrationParagraphRef.current.resetNarration()
        this.narrationParagraphRef.current.pauseNarration()
    };

    handleProgress = (state) => {
        this.setState({
            played: state.playedSeconds,
            playedPercentage: state.played
        })
    }

    seekToClickedWord = (startTime) => {
        this.setState({
            seeking: true
        })
        this.player.seekTo(parseFloat(startTime))
        this.handlePlayClick()
        this.setState({
            seeking: false
        })
    }

    ref = player => {
        this.player = player 
    }

    render() {

        const { data } = this.props;
        const { playing } = this.state;

        return (
            <React.Fragment>
                    <div className="video-container flex-center width-vw-100">
                        <div className="progress-container">
                            <div className="progress-bar" style={{ width: `${this.state.playedPercentage * 100}%`, position: "absolute", top: "0", left: "0" }}></div>
                        </div>
                        <Link to="/">
                            <div style={{ position: "absolute", top: "24px", left: "16px" }} className="flex-row text-link">
                                <img src={rightArrow} className="left-arrow"/>
                                <p className="">Back to home</p>
                            </div>
                        </Link>
                                <ReactPlayer 
                                    url={video}
                                    config={{
                                        file: {
                                            forceVideo: true,
                                            attributes: {
                                                controls: false
                                            }
                                        }
                                    }}
                                    className="video clip-circle"
                                    onPlay={this.handlePlayClick}
                                    onPause={this.handlePauseClick}
                                    onEnded={this.handleEnded}
                                    onProgress={this.handleProgress}
                                    ref={this.ref}
                                    playing={this.state.playing}
                                />
                                <div className="overlay flex-row align-center" onClick={() => this.setState({ playing: !this.state.playing })}>
                                    <img src={playIcon} className={`${playing ? "hide-icon" : "show-icon"}`} alt="play"/>
                                    <img src={pauseIcon} className={`${playing ? "show-icon" : "hide-icon"}`}alt="pause"/>
                                </div>
                    </div>
                    <div className="flex-row align-center">
                        
                        <div className="main-container">
                            <h1>Video Games for Learning</h1>
                            <h4>This essay format is an experiment in making writing more immersive.
                                I'm curious to see whether we can improve on the amount of information that readers take away from long form writing.
                                The avatar will narrate the essay to you, while some calming music will play in the background.
                                Click play when you're ready!
                            </h4>
                            <NarrationParagraph
                                data={data} 
                                ref={this.narrationParagraphRef} 
                                seekToClickedWord={this.seekToClickedWord}
                                played={this.state.played}
                                currentWordID={this.state.currentWordID}
                            /> 
                        </div>
                    </div>
            </React.Fragment>
        );
    }
}

export default Narration;