export const transcript = [
  ['Hey', 1.33, 1.75, ''],
  ["I'd", 2.2, 2.35, ''],
  ['like', 2.35, 2.47, ''],
  ['to', 2.47, 2.56, ''],
  ['introduce', 2.56, 2.98, ''],
  ['a', 2.98, 3.07, ''],
  ['more', 3.07, 3.25, ''],
  ['interactive', 3.25, 3.73, ''],
  ['format', 3.73, 4.09, ''],
  ['for', 4.09, 4.21, ''],
  ['reading', 4.21, 4.48, ''],
  ['essays.', 4.48, 4.93, ''],
  ['See,', 5.32, 5.77, ''],
  ['reading', 5.89, 6.19, ''],
  ['is', 6.19, 6.25, ''],
  ['great,', 6.25, 6.52, ''],
  ['but', 6.91, 7, ''],
  ['when', 7, 7.12, ''],
  ['it', 7.12, 7.18, ''],
  ['comes', 7.18, 7.36, ''],
  ['to', 7.36, 7.45, ''],
  ['learning,', 7.45, 7.81, ''],
  ['we', 8.05, 8.17, ''],
  ['need', 8.17, 8.32, ''],
  ['to', 8.32, 8.41, ''],
  ['be', 8.41, 8.53, ''],
  ['really', 8.53, 8.77, ''],
  ['emotionally', 8.77, 9.16, ''],
  ['connected', 9.16, 9.58, ''],
  ['to', 9.58, 9.64, ''],
  ['what', 9.64, 9.79, ''],
  ["we're", 9.79, 9.88, ''],
  ['learning.', 9.88, 10.21, ''],
  ['So', 10.75, 11.08, ''],
  ['what', 11.65, 11.8, ''],
  ['if', 11.8, 11.86, ''],
  ['we', 11.86, 11.95, ''],
  ['made', 11.95, 12.13, ''],
  ['writing', 12.13, 12.43, ''],
  ['more', 12.43, 12.61, ''],
  ['immersive?', 12.61, 13, ''],
  ['The', 13.63, 13.72, ''],
  ['best', 13.72, 14.11, ''],
  ['thing', 14.11, 14.32, ''],
  ['about', 14.32, 14.56, ''],
  ['that', 14.56, 14.65, ''],
  ['I', 14.93, 15.07, ''],
  ['could', 15.14, 15.3, ''],
  ['be', 15.36, 15.45, ''],
  ['Toby', 15.45, 15.989999999999998, ''],
  ['Lutke,', 15.989999999999998, 16.709999999999997, ''],
  ['or', 17.31, 17.43, ''],
  ['I', 17.43, 17.459999999999997, ''],
  ['could', 17.459999999999997, 17.669999999999998, ''],
  ['be', 17.669999999999998, 17.79, ''],
  ['Jordan', 17.79, 18.33, 'l'],
  ['Jordan', 18.72, 19.29, ''],
  ['Belfort!', 19.29, 19.8, ''],
  ['I', 19.88, 19.97, ''],
  ['could', 20.029999999999998, 20.29, ''],
  ['be', 20.29, 20.4, ''],
  ['Bane.', 20.4, 20.589999999999996, ''],
  ['I', 20.679999999999996, 21.4, ''],
  ['can', 21.86, 22.11, ''],
  ['be', 22.139999999999997, 22.33, ''],
  ['an', 22.349999999999998, 22.529999999999998, ''],
  ['alien...', 22.529999999999998, 23.64, ''],
  ['I', 23.7, 23.79, ''],
  ['can', 23.79, 23.939999999999998, ''],
  ['even', 23.95, 24.15, ''],
  ['be', 24.15, 24.3, ''],
  ['a', 24.3, 24.330000000000002, ''],
  ['unicorn.', 24.330000000000002, 25.080000000000002, ''],
  ['Neigh!', 25.43, 25.92, ''],
  ['In', 26.71, 26.810000000000002, ''],
  ['fact,', 26.810000000000002, 27.28, ''],
  ["I'm", 27.435, 27.495, ''],
  ['going', 27.495, 27.615000000000002, ''],
  ['to', 27.615000000000002, 27.675, ''],
  ['be', 27.675, 27.735, ''],
  ['the', 27.735, 27.825, ''],
  ['first', 27.825, 28.095, ''],
  ['unicorn', 28.095, 28.515, ''],
  ['to', 28.515, 28.605, ''],
  ['read', 28.605, 28.785, ''],
  ['you', 28.785, 28.875, ''],
  ['an', 28.875, 28.995, ''],
  ['essay.', 28.995, 29.325, ''],
  ['So', 29.595000000000002, 29.745, ''],
  ['strap', 29.755, 30.075, ''],
  ['in,', 30.075, 30.315, ''],
  ["it's", 30.405, 30.525000000000002, ''],
  ['going', 30.525000000000002, 30.675, ''],
  ['to', 30.675, 30.735, ''],
  ['be', 30.735, 30.795, ''],
  ['a', 30.795, 30.855, ''],
  ['ride.', 30.855, 31.215, ''],
]
