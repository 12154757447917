import React, { Component } from 'react';
import { GlobalStyles } from './components/styles.js';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import Home from './components/Home.js';
import VideoGamesForLearning from './components/VideoGamesForLearning.js'
import EssayPage from './components/EssayPage.js'
import InternetCities from './components/InternetCities.js'

class App extends Component {

  render() {
    return(
      <Router>
        <div>
          <GlobalStyles />       
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/interface" exact component={withRouter(EssayPage)} />
              <Route path="/internet-cities" exact component={withRouter(InternetCities)} />
            </Switch>
        </div>
      </Router>
    )
  }
  
}

export default App;