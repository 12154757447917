import React, { Component } from 'react';
import rightArrow from '../img/right-arrow.svg';
import { Link } from 'react-router-dom';
import cross from '../img/Cross.svg';
import burger from '../img/burger.svg';
import IntroSection from './IntroSection.js'
import IncomprehensibilitySection from './IncomprehensibilitySection.js'
import TacitKnowledgeSection from './TacitKnowledgeSection.js'
import DynamicSystemsSection from './DynamicSystemsSection.js'
import FutureSection from './FutureSection.js'
import CivMap from '../img/internetCities/CivMap.png'
import Fig from './Fig.js'

class InternetCities extends Component {

    state = {
        menuOpen: false,
        scrollPosition: 0
    }

    toggleMenu = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollProgress);
    }
    
    componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
    }
    
    scrollProgress = () => {
        const scrollPx = document.documentElement.scrollTop;
        const winHeightPx =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
        const scrolled = `${scrollPx / winHeightPx * 100}`;

        console.log(scrolled);

        this.setState({
            scrollPosition: scrolled
        });
    };

    render() {
        const menuOpen = this.state.menuOpen;
        const scrollPosition = this.state.scrollPosition;
        return(
            <div className="purple-background">
                <div className="mobile-navbar">
                    <img src={burger} onClick={() => this.toggleMenu()}/>
                </div>
                <div className="progress-container fixed-top">
                    <div className="progress-bar" style={{ width: `${scrollPosition}vw` }}></div>
                </div>
                <div className={`mobile-menu ${(menuOpen) ? "show": ""}`}>
                <div className="exit-menu-icon" onClick={() => this.toggleMenu()}><img src={cross}/></div>
                    <div className="mobile-menu-content">
                        
                        <Link to="/">
                            <div className="flex-row text-link">
                                <img src={rightArrow} className="left-arrow"/>
                                <p className="">Back to home</p>
                            </div>
                        </Link>
                        <h1>Navigating internet cities</h1>
                        <h4>by Oliver Hunt</h4>
                    </div>
                </div>
                {/* <div className="hero-container"><img src={CivMap}/></div> */}
                <div className="flex-row align-top home-container">
                    <div className="outside-column desktop-essay-menu">
                        <Link to="/">
                            <div className="flex-row text-link">
                                <img src={rightArrow} className="left-arrow"/>
                                <p className="">Back to home</p>
                            </div>
                        </Link>
                        <h1>Navigating internet cities</h1>
                        <h4>by Oliver Hunt</h4>
                    </div>
                    <div className="essay-center-column">
                        <p>Imagine landing in a foreign city with no information and no map. Cities are complex systems composed of many subsystems working in unison. There are buildings, businesses, parks, roads, traffic and people all bustling together to produce the phenomena around you. From ground level, it is difficult to grasp the relationships between each of these subsystems and understand the city as a whole. Without a map, your only option is to work bottom up, gradually piecing together snippets of information you learn from your local environment to build an overall picture. However, the decisions you can make are limited by what you can see, and the speed you can learn. It's obvious that exploring like this <i>alone</i> would be an inefficient way to explore a new city, because you lack context of the bigger picture.</p>
                        <br />
                        <br />
                        <p>In unknown territory, the bigger picture is the most valuable starting point. A city map is able to give you an overview of everything the city has to offer, as well as the contextual relationships between each part. It helps you see how everything fits together, by abstracting away the details to reveal the nature of the terrain. It is no substitute for exploring the city at ground level - the map can't tell you what the city looks like, what it smells like, or what if <i>feels</i> like. However, it can help you to <i>discover</i> things to explore in more depth and <i>navigate</i> efficiently to those areas. The map works <i>together</i> with your sensory experiences to increase the efficiency with which you are able to make sense of the city around you.</p>
                        <br />
                        <br />
                        <p>But city maps are absolute. They can't change based on your position or needs because, being static, they must be generalised. It is for this reason that we invented GPS. With GPS, you are the point of reference, and the map is always relative to your position. This invites the possibility of contextualising your experience based on your current location. As a result, it is significantly easier to navigate using GPS. Importantly, the GPS is integrated into a global, real-time network. This makes valuable contextual data available which can be used to improve your experience. For example, Waze uses real-time traffic data to avoid busy routes, while Zenly helps you see where your friends are. Each individual's context incrementally improves the experience for the next, and the overall system becomes recursively better over time.</p>
                        <br />
                        <br />
                        <p>This is where we are at with city wayfinding technology in the real world. Sensory experiences, maps and GPS work together to help you make sense of your physical environment. Each provides its own contextual dimension that makes navigation more efficient. We would not expect newcomers in a city to be able to find their way without it. In fact, it is so effective that it can support <i>everyone</i> to navigate autonomously. When we prevent someone from using this technology, they are no longer able to find their way. Some view this as over-reliance on technology, but it is a good example of well-designed human-computer symbiosis. The computer processes a vast sea of information into a contextually relevant subset, and the human uses the information to make relevant decisions about their environment. </p>
                        <br />
                        <br />
                        <p>GPS gives people superpowers.</p>
                        <br />
                        <br />
                        <h3>Navigating the internet</h3>
                        <br />
                        <p>We can go through the same logical exercise with the internet, replacing the characteristic features of cities. On the internet, websites are like buildings, hyperlinks are like roads, traffic comes in the form of people and information. It is a complex system with many interacting parts.</p>
                        <br />
                        <br />
                        <p>Everyday, we land on the internet without a map. Instead, search is the dominant wayfinding paradigm. It is the information equivalent of exploring the local area at ground level. A search is a hypothesis, an instance of trial and error. With enough searches, we can usually get where we're going. However, we lack the overall context of how everything fits together, which makes it inefficient and likely to miss things.</p>
                        <br />
                        <br />
                        <p>To search is to know that which you seek. The very notion of search requires you to accurately label what you are looking for. Unfortunately, in unknown territory, <i>we don't always know what we are looking for</i>. Navigating unknown territory is an exercise in <strong>discovery</strong>, not recall.</p>
                        <br />
                        <br />
                        <p>For example, climate change is a complex domain. Information is distributed across very many sources, including scientific papers, government websites, international reports, individual blogs, YouTube videos, company filings, social media and mainstream media. It covers concepts across a plethora of intellectual disciplines, many of which are at the forefront of human understanding. As a curious newcomer, one might ask questions like:</p>
                        <br />
                        <ul>
                            <li><p>What is important to know? Where can I learn more?</p></li>
                            <li><p>Where are we at right now? Where do we need to be? How did we get here?</p></li>
                            <li><p>What are the open problems? Who is working on what?</p></li>
                            <li><p>What approaches are working and what isn't working?</p></li>
                            <li><p>What are the radical ideas that we haven't tried yet?</p></li>
                            <li><p>How can I contribute?</p></li>
                        </ul>
                        <br />
                        <p>It is very difficult to answer questions like these with search, because without experience, they are marked by unknown unknowns. By definition, it is impossible to label the unknown unknowns such that a search engine can intuit your intention. Instead, we must proceed by trial and error, while the search engine can only serve up information related to the <i>specific questions</i> we ask.</p>
                        <br />
                        <br />
                        <p>But this is not to say that the average person is incapable of understanding the answers to these broad questions. It is to say that we haven't invented the GPS that makes navigating complex information domains accessible to the average person. Just like people need superpowers to navigate complex information in physical environments, they need superpowers to navigate complex information on the internet.</p>
                        <br />
                        <br />
                        <p>So what if we gave people internet superpowers? Well, I would argue that the majority of our time is spent attempting to answer open-ended questions. In fact, the questions that matter in the world are the ones that don't yet have answers. Most of the time, we <i>don't know</i> what we're looking for. But the information we need is usually on the internet, <i>somewhere</i>. We just don't know where to find it.</p>
                        <br />
                        <br />
                        <p>Climate change is one of those things. If everyone had the ability to answer questions like the ones above, who knows what would happen? If the internet has taught us anything, its that people are very creative when they are given the right tools. Much like it was difficult to predict Uber, or Zenly, or Waze before GPS existed, it's difficult to predict how people might use internet GPS. However, giving people tools to <i>synthesise</i> the vast amount of information on the internet feels like it could have similarly massive impact.</p>
                        <br />
                        <br />
                        <p>It's time to invent the GPS for the internet.</p>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        )
    }
}

export default InternetCities;