import { createGlobalStyle} from "styled-components";
import { breakpoints } from "./Breakpoints.js";

export const GlobalStyles = createGlobalStyle`

    .flex-center {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .red-background {
        background-color: #FFF6F6;
    }

    .purple-background {
        background-color: rgba(242, 235, 255, 0.6)
    }

    p {
        font-family: Inter, sans-serif;
        font-size: 16px;
        line-height: 24px;
        margin: 0px !important;
        text-decoration: none !important;
    }

    .paragraph {
        line-height: 28px !important;
    }

    h1 {
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-weight: bold;
    }

    h2 {
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-weight: normal;
    }

    h3 {
        font-family: Inter, sans-serif;
        font-size: 20px;
        font-weight: normal;
    }

    h4 {
        font-family: Inter, sans-serif;
        font-style: italic;
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 6px;
    }

    h5 {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        margin: 0px;
        margin-bottom: 8px !important;
        color: #A4A1A1;
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    }

    h6 {
        font-family: Inter, sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: normal;
        margin-bottom: 2px;
        margin-block-start: none !important;
        margin-inline-start: none !important;
        color: #A4A1A1;
        margin: 0px !important;
    }

    .essay-section-heading {
        margin-top: 48px !important;
        margin-bottom: 32px !important;
    }

    .essay-body-text {
        font-family: "AdobeCaslonPro" !important;
        font-size: 18px;
        font-weight: medium;
        line-height: 24px;
        color: #040404;
    }

    .column-heading {
        ${breakpoints("margin-bottom", "px !important", [
            { 5000: 32 },
            { 1200: 32 },
            { 800: 32 },
            { 600: 16 },
            { 450: 16 }
        ])};
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    hr {
        margin: 32px 0px;
        border: none;
        height: 1px;
        background-color: #D2D2D2;
    }

    .text-link {
        display: inline-flex !important;
        border-bottom: dotted 1px black;
        text-wrap: wrap;
        &:hover {
            border-bottom: solid 1px black;
            cursor: pointer !important;
        }
    }

    .lottie {
        ${breakpoints("max-width", "px", [
            { 5000: 600 },
            { 1200: 600 },
            { 800: 600 },
            { 600: 350 },
            { 450: 350 }
        ])};
        position: absolute;
        ${breakpoints("left", "px", [
            { 5000: 240 },
            { 1200: 30 },
            { 800: 40 },
            { 600: 0 },
            { 450: 0 }
        ])};
        height: 120px;
    }

    .inline-text-link {
        display: inline-flex !important;
        border-bottom: dotted 1px black;
        text-wrap: wrap;
        &:hover {
            border-bottom: solid 1px #485BFF;
            cursor: pointer !important;
            transition: 0.2s ease;
            color: #485BFF !important;
        }
    }

    .quote-container {
        border-left: solid 4px black;
        padding-left: 24px;
    }

    .inline-text-link h6 {
        &:hover {
            color: #485BFF !important;
        }
    }

    .hero-container {
        display: flex;
        justify-content: center;
        overflow: hidden;
        background-color: black;
        object-fit: contain !important;
    }

    .hero-container img {
        ${breakpoints("height", "px", [
            { 5000: 480 },
            { 1200: 480 },
            { 800: 480 },
            { 600: 480 },
            { 450: 280 }
        ])};

    }

    .callout-container {
        padding: 24px;
        background-color: #FBE8E8;
    }

    .right-arrow {
        margin-left: 8px;
        width: 12px;
    }

    .left-arrow {
        margin-right: 8px;
        width: 12px;
        transform: rotate(180deg);
    }

    .sub-text {
        color: #979797;
        margin-top: 6px !important;
    }

    .flex-row {
        display: flex;
        flex-direction: row;
    }

    .align-center {
        align-items: center;
        justify-content: center;
    }

    .align-top {
        align-items: flex-start;
        justify-content: center;
    }

    .main-container {
        width: 720px;
    }

    .padding-24 {
        padding: 0px 24px;
    }

    .width-100 {
        width: 100% !important;
    }

    .width-vw-100 {
        width: 100vw !important;
    }

    .clip-circle {
        clip-path: circle(100px at center);
        animation-name: radiate;
        animation-duration: 1s;
    }

    /* ###### Video styles ###### */

    .video-container {
        width: 100% !important;
        background: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(4px);
        position: sticky;
        top: 0;
        animation-name: background-rainbow;
        animation-duration: 30s;
        animation-iteration-count: infinite;
        transition: 5s ease;
    }

    .video {
        display: block;
        padding: 16px 0px;
        height: 200px !important;
        height: 200px;
        animation-name: grow;
        animation-duration: 1s;
    }

    .overlay {
        position: absolute;
        height: 200px !important;
        width: 200px !important;
        border-radius: 200px;
        background-color: rgb(51,60,84,0.6);
        opacity: 0;
        transition: .5s ease;
        margin-top: 20px;
        &:hover {
            opacity: 1;
            cursor: pointer;
        }
    }



    .show-icon {
        display: block;
    }

    .hide-icon {
        display: none;
    }

    @keyframes grow {
        0% {
            height: 0px;
        }

        100% {
            height: 200px
        }
    }

    @keyframes radiate {
        0% {
            clip-path: circle(2px at center);
        }

        100% {
            clip-path: circle(100px at center);
        }
    }

    /* ###### Progress styles ###### */

    .progress-container {
        ${breakpoints("height", "px", [
            { 5000: 4 },
            { 1200: 4 },
            { 800: 2 },
            { 600: 2 },
            { 450: 2 }
        ])};
    }

    .progress-bar {
        height: 4px;
        background-color: black;
        z-index: 5;
        transition: .2s ease;
    }

    /* ###### Paragraph styles ###### */

    .m-narration {
        line-height: 1.8;
        padding-top: 40px;
        .a-container {
          padding-bottom: 110px;
        }
    }

    /* ###### Word styles ###### */

    @keyframes highlight {
        0% { 
        }

        20% {
            background-color: blue;
            color: white;
            transform: scale(1.2);
        }

        60% {
            background-color: blue;
            color: white;
            transform: scale(1);
        }

        100% {
            background-color: blue;
            color: white;
        }
    }

    .animation-running {
        animation-play-state: running !important;
        position: relative;
        z-index: 10;
    }
    
    .a-narrationWord {
        background-color: white;
        border: none;
        border-radius: 4px;
        font: inherit;
        cursor: pointer;
        outline: none;
        display: inline;
        white-space: normal;
        animation-name: highlight;
        animation-play-state: paused;
        animation-timing-function: linear;
        padding: 0px;
        font-size: 16px;
        font-family: Helvetica;
        span {
            padding: 0px 2px;
            font-family: Inter, sans-serif;
            font-size: 18px;
            line-height: 28px;
            color: inherit;
        }
    }

    .a-narrationWord:hover {
        background-color: #F2EFFF;
        border-radius: 4px;
    }

    /* ###### Sticky notes ###### */

    .sticky-note-area {
        background-color: #fafbff;
        min-height: 100vh;
        min-width: calc((100vw - 832px) / 2);
        padding: 24px;
        overflow: hidden;
    }

    .sticky-note-container {
        max-width: 200px;
        background-color: #FFF0A3;
        box-shadow: 0px 2px 10px #DEDEDE;
        padding: 16px;
    }

    .sticky-note {
        width: 100% !important;
        font-family: Arial;
        font-size: 16px;
        outline: none;
        border: none;
        background-color: #FFF0A3;
    }

    .sticky-note-button {
        width: 100%;
        background-color: #21affb;
        outline: none;
        border: none;
        border-radius: 8px;
        height: 40px;
        color: white;
        margin-top: 16px;
        cursor: pointer;
        &:hover {
            background-color: #0090DD;
        }
    }

    .note {
        outline: none;
        border: none;
        background-color: #FFF0A3;
        border: solid 2px #FFF0A3;
        position: absolute; 
        padding: 16px;
        box-shadow: 0px 2px 10px #DEDEDE;
        width: 200px;
        height: 200px;
        word-wrap: break-word;
        object-fit: contain;
        cursor: pointer;
        .text {
            max-width: 168px !important;
            font-family: Arial;
            font-size: 16px;
            overflow-y: scroll;
        }
        &:hover {
            border: solid 2px #21affb;
        }
    }

    .home-container {
        ${breakpoints("margin-top", "px", [
            { 5000: 80 },
            { 1200: 80 },
            { 800: 32 },
            { 600: 32 },
            { 450: 32 }
        ])};
        object-fit: contain;
    }

    .hide-mobile {
        ${breakpoints("display", "", [
            { 5000: "none" },
            { 1200: "none" },
            { 800: "none" },
            { 600: "none" },
            { 450: "none" }
        ])};
    }

    .outside-column {
        width: 240px;
        ${breakpoints("padding-left", "", [
            { 5000: "0px" },
            { 1200: "0px" },
            { 800: "32px" },
            { 600: "16px" },
            { 450: "16px" }
        ])};
    }

    .center-column {
        ${breakpoints("padding", "", [
            { 5000: "0px 40px" },
            { 1200: "0px 40px" },
            { 800: "48px 32px" },
            { 600: "48px 16px" },
            { 450: "48px 16px" }
        ])};
    }

    .essay-center-column {
        max-width: 600px;
        ${breakpoints("margin", "px", [
            { 5000: 40 },
            { 1200: 40 },
            { 800: 40 },
            { 600: 16 },
            { 450: 16 }
        ])};
        ${breakpoints("margin-top", "px", [
            { 5000: 0 },
            { 1200: 0 },
            { 800: 0 },
            { 600: 16 },
            { 450: 16 }
        ])};
    }

    .avatar-video {
        height: 100% !important;
    }

    .main-background {
        animation-name: background-rainbow;
        animation-duration: 32s;
        animation-iteration-count: infinite;
        overflow: auto;
        min-height: 100vh;
    }

    .clearfix:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
    }

    .thought-space {
        max-width: 240px;
        animation-name: thought-space-rainbow;
        animation-duration: 30s;
        animation-iteration-count: infinite;
        padding: 16px;
        margin: 16px 0px;
        &:hover {
            border: solid 2px #D2D1D1;
            cursor: pointer;
        }
    }

    @keyframes background-rainbow {
        0% {
            background-color: rgba(255, 232, 232, 0.6);
        }

        25% {
            background-color: rgba(242, 235, 255, 0.6);
        }

        50% {
            background-color: rgba(232, 237, 255, 0.6);
        }

        75% {
            background-color: rgba(235, 251, 255, 0.6);
        }

        100% {
            background-color: rgba(255, 232, 232, 0.6 );
        }
    }

    @keyframes thought-space-rainbow {
        0% {
            background-color: rgb(255, 232, 232);
        }

        25% {
            background-color: rgb(242, 235, 255);
        }

        50% {
            background-color: rgb(232, 237, 255);
        }

        75% {
            background-color: rgb(235, 251, 255);
        }

        100% {
            background-color: rgb(255, 232, 232);
        }
    }

    .shooting-star {
        z-index: 1;
        width: 4px;
        height: 4px;
        background-color: black;
        top: 40px;
        left: 40px;
        position: absolute;
        transform: rotate(109deg);
    }

    .star-1 {
        animation-name: stara;
        animation-duration: 2s;
        animation-timing-function: ease;
    }

    .star-2 {
        animation-name: starb;
        animation-duration: 3s;
        animation-delay: 3s;
    }

    .star-3 {
        animation-name: starc;
        animation-duration: 3s;
        animation-delay: 4s;
    }

    .star-4 {
        animation-name: stard;
        animation-duration: 3s;
        animation-delay: 5s;
    }

    @keyframes stara {
        0% {
            transform: rotate(109deg);
            height: 4px;
        }

        50% {
            height: 48px;
        }

        100% {
            height: 24px;
            transform: rotate(109deg) translate(-400px, -600px);
            background-color: white;
        }
    }

    @keyframes starb {
        0% {
            transform: rotate(109deg);
            height: 4px;
        }

        50% {
            height: 48px;
        }

        100% {
            height: 24px;
            transform: rotate(94deg) translate(-200px, -800px);
            background-color: white;
        }
    }

    @keyframes starc {
        0% {
            transform: rotate(109deg);
            height: 4px;
        }

        50% {
            height: 48px;
        }

        100% {
            height: 24px;
            transform: rotate(64deg) translate(-400px, -600px);
            background-color: white;
        }
    }

    @keyframes stard {
        0% {
            transform: rotate(109deg);
            height: 4px;
        }

        50% {
            height: 48px;
        }

        100% {
            height: 24px;
            transform: rotate(72deg) translate(-400px, -600px);
            background-color: white;
        }
    }

    .birds-animation {
        width: 300px !important;
        height: 300px !important;
        position: absolute;
        top: 40px;
        left: 40px;
    }

    .subheading-link-menu {
        margin-top: 32px;
        max-height: 400px !important;
        overflow: scroll;
    }

    .video-container {
        background-color: black;
        width: 100%;
    }

    .mobile-menu {
        width: 285px;
        height: 100vh !important;
        background: rgba(255, 255, 255, 0.8);
        border-right: 1px solid #DFDFDF;
        backdrop-filter: blur(10px);
        overflow: scroll;
        padding-left: 16px;
        padding-top: 16px;
        padding-right: 16px;
        position: fixed;
        top: 0px;
        display: none;
    }

    .show {
        display: block;
    }

    .mobile-menu-content {
        width: 208px;
        padding-top: 56px;
    }

    .exit-menu-icon {
        float: right !important;
        margin-bottom: 20px;
        position: sticky;
        top: 0px;
        cursor: pointer;
    }

    .mobile-navbar {
        position: sticky !important;
        top: 0px;
        height: 40px;
        padding-left: 16px;
        background: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(4px);
        ${breakpoints("display", "", [
            { 5000: "none" },
            { 1200: "flex" },
            { 800: "flex" },
            { 600: "flex" },
            { 450: "flex" }
        ])};
        align-items: center;
    }

    .desktop-essay-menu {
        ${breakpoints("display", "", [
            { 5000: "block" },
            { 1200: "none" },
            { 800: "none" },
            { 600: "none" },
            { 450: "none" }
        ])};
        position: sticky !important;
        top: 80px;
    }

    .fixed-top {
        position: sticky;
        ${breakpoints("top", "px", [
            { 5000: 0 },
            { 1200: 0 },
            { 800: 40 },
            { 600: 40 },
            { 450: 40 }
        ])};
    }

    .about-me {
        ${breakpoints("margin-left", "px", [
            { 5000: 108 },
            { 1200: 108 },
            { 800: 16 },
            { 600: 16 },
            { 450: 16 }
        ])};
        ${breakpoints("margin-right", "px", [
            { 5000: 0 },
            { 1200: 0 },
            { 800: 16 },
            { 600: 16 },
            { 450: 16 }
        ])};
        max-width: 480px;
        ${breakpoints("margin-top", "px", [
            { 5000: 0 },
            { 1200: 0 },
            { 800: 108 },
            { 600: 108 },
            { 450: 108 }
        ])};
    }
`