import React, { Component } from 'react';
import TextLink from './textLink.js'

class Fig extends Component {
    render() {
        const { src, caption } = this.props;
        return(
            <div>
                <img src={src} style={{ width: "100%", marginBottom: "4px" }} />
                <h6>{caption}</h6>
            </div>
        )
    }
}

export default Fig;