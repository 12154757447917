import React, { Component } from 'react';
import fig1 from '../img/interfaceEssay/fig1.gif'
import fig2 from '../img/interfaceEssay/fig2.png'
import fig3 from '../img/interfaceEssay/fig3.png'
import fig4 from '../img/interfaceEssay/fig4.png'
import TextLink from './textLink.js';
import CaptionTextLink from './captionTextLink.js';
import Fig from './Fig.js';

class IntroSection extends Component {
    render() {
        return(
            <div>
                <a name="top">
                    <p>
                    Interfaces arise where systems interact. Scientific literature is the meeting place between people and ideas at the frontiers of human knowledge. It acts as the interface for science, enabling interaction between people and the shared record of knowledge.
                    </p>
                </a>
                <br />
                <br />
                <p>
                In this article, I argue that it is failing at each of its core functions, and discuss future directions. There are two central ideas:
                </p>
                <ul>
                    <li><p>Literature as an interface is limiting the speed at which we can make progress, among both experts and non-experts.</p></li>
                    <li><p>Science models the world, and models should be represented in data, not just language. </p></li>
                </ul>
                <br />
                <p>
                    Interface and incentive structures go hand in hand in science. However, many good articles have been written about the troublesome funding and incentive structures, and their effect on how science gets done. To me, focusing solely on the incentive structures misses the elephant in the room: that we are still relying on <strong>17th Century technology</strong> to capture and share knowledge. So, I'm going to focus entirely on literature as an <strong>interface</strong> throughout this article.
                </p>
                <br />
                <br />
                <p>
                To give you an idea of structure, I'll start with a (very) brief history of journals, then move on to describe the problems with literature as an interface, and finish with ideas for future directions.
                </p>
                <br />
                <br />

                <a name="journals"><h2>The evolution of journals</h2></a>
                <br />
                <p>
                The first ever scientific journal is widely regarded to be <TextLink url="https://royalsocietypublishing.org/doi/10.1098/rstl.1665.0002" text="Philosophical Transactions"></TextLink>, published in 1665. In order to calibrate your sense of time, 1665 was the final outbreak of the bubonic plague in London, twenty years before Isaac Newton published his laws of motion in Principia Mathematica (1687), and over a hundred years before the declaration of American Independence (1776).
                </p>
                <br />
                <br />
                <p>
                Philosophical Transactions was introduced shortly after the establishment of the <TextLink url="https://royalsociety.org/?gclid=CjwKCAjwxo6IBhBKEiwAXSYBsyXQi2Je_BiDOgiYM6vfcIwZhjdX5Tez5xFNqAhXR7k_1x-YsDtTEBoCOxsQAvD_BwE" text="Royal Society"></TextLink>, conceived as a means of saving the labour of coordinating letters between those engaged in science. Instead, a central party was responsible for collating, publishing and distributing the most important developments in the natural sciences. As such, it functioned primarily as a news service to update and coordinate a distributed community of scientists.
                </p>
                <br />
                <br />
                <Fig src={fig2}/>
                <h6>The first page of the first scientific journal ever produced (photo taken from <CaptionTextLink url="https://royalsocietypublishing.org/doi/10.1098/rstl.1665.0002" text="here"></CaptionTextLink>).</h6>
                <br />
                <br />
                <p>
                Thereafter, Philosophical Transactions acted as a model for the development of other journals across an increasingly wide array of disciplines. Together, they represented the proliferation of the modern scientific revolution, enabling the connection and coordination of scientists distributed in their geography.
                </p>
                <br />
                <br />
                <p>
                A major development in this trajectory, not limited to scientific journals alone, was the invention of graphical displays of information. Initially by, J.H. Lambert (1728-1777), followed by William Playfair (1783), visual displays of data represented one of the great interface transitions of history. Long data tables gave way to graphs, abstracting individual datapoints to reveal trends in data otherwise hidden to the observer, paving the way for modern statistical thought. It's hard to overstate the importance of this logic jump because we take it so for granted. Graphical displays of information are the foundation of every discipline concerned with quantitative information, because they make information human readable. Relationships exist in large quantities of data, but without graphs, we cannot see them. Science therefore adopted graphs to help make sense of experimental data and they became intregrated into literature.
                </p>
                <br />
                <br />
                <Fig src={fig3}/>
                <h6>William Playfair's earliest graphical chart for displaying import/export data over time. Image originally found in Ed Tufte's "Visual Displays of Quantitative Information"</h6>
                <br />
                <br />
                <p>
                By the turn of the 20th Century, the majority of journals had converged on the format we see in journal articles today. Since then, journal publishing has moved almost entirely online, despite maintaining the same format, bringing us to the present day.
                </p>
                <br />
                <br />
                <p>
                In the present day, the literature (through individual journals) performs the following core functions:
                </p>
                <ul>
                    <li><p><strong>Capture</strong> - when new knowledge is produced, the literature is the central place where it is captured across all academic disciplines. Its role is to solicit all new knowledge such that it can be found by other people.</p></li>
                    <li><p><strong>Storage</strong> - we don't want to lose anything that may become important in the future, or repeat work that has already been done. So, its role is to make sure that we have a record of any scientific work that is carried out so that it can be referenced.</p></li>
                    <li><p><strong>Communication + learning</strong> - there is more work than can be done by one person. So, literature exists to communicate findings between people, enabling them to learn from and build on each others' work.</p></li>
                </ul>
                <br />
                <p>
                Let's take a moment to compare the beginning and the end of our 336 year journey:
                </p>
                <br />
                <br />
                <Fig src={fig4}/>
                <h6>(Left) The first page of the first scientific journal ever produced (photo taken from <CaptionTextLink url="https://royalsocietypublishing.org/doi/10.1098/rstl.1665.0002" text="here"></CaptionTextLink>). (Right) The first page of a recent paper outlining the efficacy and safety of the Moderna COVID vaccine in the NEJM in 2021 (photo taken from <CaptionTextLink url="https://www.nejm.org/doi/pdf/10.1056/NEJMoa2035389?articleTools=true" text="here"></CaptionTextLink>)</h6>
                <br />
                <br />
                <p>
                It's hard to ignore that they are <i>shockingly similar</i>. 
                </p>
                <br />
                <br />
                <p>
                I want to use the rest of this essay to argue that we are due a major upgrade to scientific publishing as an <i>interface</i>, and push on some ideas of what that interface might look like. Without further ado.
                </p>
                <br />
                <br />
            </div>
        )
    }
}

export default IntroSection;