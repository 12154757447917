import React, { Component } from 'react';
import fig11 from '../img/interfaceEssay/fig11.jpeg'
import fig12 from '../img/interfaceEssay/fig12.png'
import fig13 from '../img/interfaceEssay/fig13.png'
import Fig from './Fig.js';
import TextLink from './textLink';
import CaptionTextLink from './captionTextLink';
import Quote from './Quote.js';

class FutureSection extends Component {
    render() {
        return(
            <div>
                <a name="future"><h2>Looking to the future</h2></a>
                <br />
                <p>The ongoing development of rigorous scientific thought is at the foundation of human progress. However, it's clear that the current scientific interface is insufficient for modern needs. </p>
                <br />
                <br />
                <p>We develop new tools to <TextLink url="http://worrydream.com/MediaForThinkingTheUnthinkable/" text='"make previously unthinkable thoughts thinkable"' />. Collectively, we are at our most powerful when <i>everyone</i> is able to leverage the latest scientific understanding to make informed decisions. Scientists are at their most powerful when they are empowered to spend all of their time doing creative science, while technology abstracts the most repetitive work.</p>
                <br />
                <br />
                <p>Scientific papers are <strong>17th Century technology</strong> that do not give us these powers.</p>
                <br />
                <br />
                <p>To recap our conversation so far, I believe that the scientific interface should:</p>
                <ul>
                    <li><p>Be a resource for <i>everyone</i> to learn about and apply the limits of our collective understanding to their specific needs in real-time, without requiring expert prior knowledge.</p></li>
                    <li><p>Incorporate original data as a first-class object.</p></li>
                    <li><p>Enable multiple, powerful representations of complex systems that can be dynamically controlled by the user, which promote understanding and prediction of whole systems.</p></li>
                    <li><p>Mitigate human biases that prevent us from finding the truth.</p></li>
                    <li><p>Constantly solicit tacit knowledge from participants with low friction and integrate this into the wider context, as a means of connecting and integrating individual findings into the larger body of knowledge.</p></li>
                </ul>
                <br />
                <br />
                <p>So, where does this all lead?</p>
                <br />
                <br />
                <p>If we assume that systems are the key feature of science and engineering, and dynamic simulations are the best way to represent systems, then it seems clear that we need to encode knowledge in a form native to dynamic simulations. My central idea here is that the scientific record of the future should be constructed from a deeply connected web of structured data that can be interpreted by computers to produce dynamic simulations. This can be <i>supported</i> by language as one type of representation, but the focus should be on structured data.</p>
                <br />
                <br />
                <p>Given this, I believe that the future of the scientific interface should resemble an API, or an internet of models. An API lets you pull underlying data from storage and represent that however you want, under the constraints of the data. It lets you <strong>interface</strong> with data that is collected, maintained and integrated into a central location. However, it does not precisely determine how that data is represented to the user. </p>
                <br />
                <br />
                <p>What we want from the scientific record is to be able to dynamically pull out results that are relevant to our needs, together with its full interconnected context. We want to be able to use that data <i>for our own purposes</i>, either in representing it directly to learn from it, or in dynamically constructing models of systems which <i>apply</i> the results in a new context. As such, the same underlying data can be used in many ways that are determined by the <i>user</i>, not the <i>author</i>. The interface should handle the construction of multiple, dynamic representations of that data that are reactive to the user's needs. </p>
                <br />
                <br />
                <Fig src={fig11} />
                <h6>What if you could 'grab' any result from science to understand it in more detail, and 'add' it to a dynamic simulation in real-time? What if you could create models of complex systems in seconds, drawing on the full range of human knowledge, to predict an answer to a previously unanswered question?</h6>
                <br />
                <br />
                <p>If we think about the scientific record like an API, it opens up the potential for an entire ecosystem of products to directly contribute to and represent the same underlying source of scientific information, which are all connected by a common <strong>protocol</strong>. New results can be contributed to the protocol from different clients, while maintaining those results in a format that can be used <strong>interoperably</strong> by everyone else. Different clients can then pull arbitrary combinations of data from the API, and specialise in representing that data to the user in specific ways. Nobody owns it, the protocol is open to everyone and there are no gatekeepers in the way of producing and spreading knowledge.</p>
                <br />
                <br />
                <p>In this future, mainstream media no longer interprets scientific information. Instead, they draw <i>directly</i> from the source. In fact, <i>all scientific communication and learning</i>, including every textbook, lecture and online course draws from the <i>same, original, primary</i> artifacts accessible through the same interface. Expert scientists, lay readers and novice students all work from the same source of information, just represented in different ways. Information always maintains its deeply connected context, and entry points for learning are always connected to reported information. At the point of consuming content, the reader can dynamically change representations to get a better understanding, and bring new information in and out context as personally required. Importantly, information is always up to date regardless of where it is represented, because all information is connected to the same protocol.</p>
                <br />
                <br />
                <p>By analogy, it should act like a Google search, in that users can ask questions of any part of the scientific record. However, it should be unlike a Google search in that it <i>synthesises</i> information across all search results to produce a <i>systems-level understanding</i> of the search query, accessible to the user through many kinds of rich, dynamic representations that maintain individual results in the context of wider systems.</p>
                <br />
                <br />
                <p>For those of you who ask the question of what 'dynamic simulations' or 'systems representations' looks like to the user, I'll lean on the work of Bret Victor as the most compelling potential future (see below). Imagine that you are able to manipulate all scientific knowledge at conversational speed, and use it to produce dynamic visualisations that support what you're saying. Imagine that you can actually <i>draw ideas</i> in front of you that are deeply connected to the frontier of human understanding, and which dynamically react to your input. </p>
                <br />
                <br />
                <Fig src={fig12} />
                <h6>These diagrams are taken from Bret Victor's work on <CaptionTextLink url="http://worrydream.com/cdg/ResearchAgenda-v0.19-poster.pdf" text='"Humane Representations of Thought"' />, which is a precursor to his current work at <CaptionTextLink url="https://dynamicland.org/" text="Dynamicland" />. Dynamicland is an attempt to invent a new medium of thought that engages the full range of human intellectual capabilities. If you haven't come across it before, I recommend that you check it out. </h6>
                <br />
                <br />
                <p>Producing these kinds of representations is dependent on having a deeply connected context of underlying information. Notice that there isn't a <i>specific</i> artifact. Instead, representations are produced dynamically as required by the user. The user is in control of how they represent information, but they can access the full range of collective understanding. We could not construct these kinds of representations right now, because the data they rely on (mostly) isn't in the public domain.</p>
                <br />
                <br />
                <p>In contributing structured, connected data as the primary encoding of knowledge, we lay the groundwork for producing powerful representations like these. Simultaneously, by layering public social interactions on top of those contributions, we open up the maximum level of scrutiny for newly produced scientific results. Whenever a new piece of data is contributed, it's possible to see who contributed it, and it's possible to refute, remix and reversion that information at the source. This context is available to everyone at the point of consuming the content, should they desire it. </p>
                <br />
                <br />
                <a name="weather"><h3>Weather - a current example?</h3></a>
                <br />
                <p>In the present, we can look to the weather as an example provided earlier in this piece. Most people interact with weather models on a daily basis. Weather modelling is a complex field, requiring deeply involved full-time work from thousands of people. It took many individual findings and steps forward to produce our current understanding of weather forecasting. However, as a user, we have access to many rich representations of weather data supplied by central APIs to applications. We can track what is happening across the entire world climate in real time from any device, and make informed decisions about what to wear in a given moment. In doing so, we <i>abstract</i> all scientific literature concerning weather modelling and climate systems into strong dynamic representations that are applied to our own purposes.</p>
                <br />
                <br />
                <Fig src={fig13} />
                <h6>Simulated representations of weather systems available to consumers. (Top left) Screenshot from the app "Clime" available on the App Store. (Top right) Screenshot from the app "Weather" from the Not Boring app series by Andy Works - it's awesome, <CaptionTextLink url="https://apps.apple.com/us/app/not-boring-weather/id1531063436" text="you should get it" />. (Bottom) Screenshot from a BBC news weather forecast.</h6>
                <br />
                <br />
                <p>From this, it takes a bit of imagination to extrapolate a fully dynamic, connected web of information. Imagine that you could start with a weather model, and dynamically integrate some recent results on the effect of carbon emissions on future climate patterns. You can pull this data into your model, and extrapolate the weather patterns over the next 20 years based on different scenarios of carbon emissions. You can dynamically create charts and visualisations which help you to understand what the different possible scenarios are, and what variables in the present have the biggest effect on the future climate. One suggested variable is to eat less meat. In the context of your climate exploration, you can dynamically model the effect of changing your consumption of different meats, and progressively apply it to larger numbers of the population. You can then build in global animal population numbers based on all of your prior modelling assumptions, and see how the changes in meat consumption affect animal populations over time. Finally, you investigate the contextual effect of these assumptions on global GDP, looking at the effect of changing consumption habits on food markets, at the local level all the way up to international level, zooming in and out of contexts.</p>
                <br />
                <br />
                <p>Notice that in this example, we spanned climate science, ecology and economics in swift succession. Creating these dynamic representations required synthesis of significant numbers of individual findings, though that work was entirely handled by the interface. As a user of the interface, we benefit from the highest level of applied system-level understanding without needing to do the painstaking work of consuming each individual finding. Since all the data was associatively connected, we were able to dynamically pull in new systems and connect them to our existing assumptions in context. We can ask our own specific questions, and <i>apply</i> existing data to retrieve <i>evidence-backed</i> answers from the best-available sources. </p>
                <br />
                <br />
                <p>Generating this kind of understanding currently takes weeks, if not months, of expert attention. Once generated, it is shared in the form of one or several articles, where the knowledge is primarily encoded with language. It may answer some <i>specific</i> questions about climate, meat consumption and economic impact over time, but those answers are dependent on specific assumptions which cannot be changed at the point of consuming the content to answer new questions. Complex ideas must be described with complex vocabulary in long stretches of prose, which hides the meaning from untrained observers.</p>
                <br />
                <br />
                <a name="conclusion"><h3>Conclusion</h3></a>
                <br />
                <p>Unfortunately, we are a <i>long way</i> from this future. But we should be optimistic! By improving one interface, we can improve the way we think. Scientists can benefit from powerful methods of understanding systems, and use them to create new understanding and new technology. Society can use it to become more scientifically literate, and make more informed decisions in every area of life. Students can use it to learn about the <i>frontiers</i> of knowledge, instead of using textbooks which lag the cutting edge by decades.</p>
                <br />
                <br />
                <p>There are many parallel innovations required to achieve this. One that is well within our grasp is to change how scientific knowledge is captured and distributed. Some short-term steps should include claim-level commenting, modularity of literature artifacts, structured data modules and version control. However, the most compelling future will arise once scientific thought is <i>fully</i> opened up to everyone, through dynamic simulation. We shouldn't let small steps along the way distract us from this important long-term goal.</p>
                <br />
                <br />
                <p>Scientific literature is currently a bottleneck on our ability to leverage scientific thought. In turn, this reduces our ability to make progress at the frontiers of human knowledge, but also in our everyday lives. Computers are yet to have a strong impact on the way we think about science, because we're still treating them as though they are pen and paper.</p> 
                <br />
                <br />
                <p>Pen and paper took us as far as the moon. It's about time we went to the stars.</p>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
           
           
           
           </div>
        )
    }
}

export default FutureSection;