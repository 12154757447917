import React, { Component } from 'react';
import rightArrow from '../img/right-arrow.svg';
import { Link } from 'react-router-dom';
import cross from '../img/Cross.svg';
import burger from '../img/burger.svg';
import IntroSection from './IntroSection.js'
import IncomprehensibilitySection from './IncomprehensibilitySection.js'
import TacitKnowledgeSection from './TacitKnowledgeSection.js'
import DynamicSystemsSection from './DynamicSystemsSection.js'
import FutureSection from './FutureSection.js'
import cover from '../img/interfaceEssay/Cover.png'
import Fig from './Fig.js'

class EssayPage extends Component {

    state = {
        menuOpen: false,
        scrollPosition: 0
    }

    toggleMenu = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollProgress);
    }
    
    componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
    }
    
    scrollProgress = () => {
        const scrollPx = document.documentElement.scrollTop;
        const winHeightPx =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
        const scrolled = `${scrollPx / winHeightPx * 100}`;

        console.log(scrolled);

        this.setState({
            scrollPosition: scrolled
        });
    };

    render() {
        const menuOpen = this.state.menuOpen;
        const scrollPosition = this.state.scrollPosition;
        return(
            <div className="red-background">
                <div className="mobile-navbar">
                    <img src={burger} onClick={() => this.toggleMenu()}/>
                </div>
                <div className="progress-container fixed-top">
                    <div className="progress-bar" style={{ width: `${scrollPosition}vw` }}></div>
                </div>
                <div className={`mobile-menu ${(menuOpen) ? "show": ""}`}>
                <div className="exit-menu-icon" onClick={() => this.toggleMenu()}><img src={cross}/></div>
                    <div className="mobile-menu-content">
                        
                        <Link to="/">
                            <div className="flex-row text-link">
                                <img src={rightArrow} className="left-arrow"/>
                                <p className="">Back to home</p>
                            </div>
                        </Link>
                        <h1>Building a 21st Century Interface for Science</h1>
                        <h4>by Oliver Hunt</h4>
                        <div className="subheading-link-menu">
                            <a href="#top"><h5>Introduction</h5></a>
                            <a href="#incomprehensibility"><h5 >The incomprehensibility of academia</h5></a>
                            <a href="#synthesis"><h5><span>Knowledge arises from synthesis of many materials</span></h5></a>
                            <a href="#media"><h5>Passing the buck to mainstream media</h5></a>
                            <a href="#tacit"><h5>Tacit knowledge is the glue that holds literature together</h5></a>
                            <a href="#systems"><h5><span>Representing knowledge as dynamic systems</span></h5></a>
                            <a href="#data"><h5>Do you speak data?</h5></a>
                            <a href="#mitigation"><h5><span>Mitigating fallible behaviours in the process of science</span></h5></a>
                            <a href="#future"><h5><span>Looking to the future</span></h5></a>
                            <a href="#weather"><h5>The weather - a current example?</h5></a>
                            <a href="#conclusion"><h5>Conclusion</h5></a>
                        </div>
                    </div>
                </div>
                {/* <div className="hero-container"><img src={cover}/></div> */}
                <div className="flex-row align-top home-container">
                    <div className="outside-column desktop-essay-menu">
                        <Link to="/">
                            <div className="flex-row text-link">
                                <img src={rightArrow} className="left-arrow"/>
                                <p className="">Back to home</p>
                            </div>
                        </Link>
                        <h1>Building a 21st Century Interface for Science</h1>
                        <h4>by Oliver Hunt</h4>
                        <div className="subheading-link-menu">
                            <a href="#top"><h5>Introduction</h5></a>
                            <a href="#incomprehensibility"><h5 >The incomprehensibility of academia</h5></a>
                            <a href="#synthesis"><h5><span>Knowledge arises from synthesis of many materials</span></h5></a>
                            <a href="#media"><h5>Passing the buck to mainstream media</h5></a>
                            <a href="#tacit"><h5>Tacit knowledge is the glue that holds literature together</h5></a>
                            <a href="#systems"><h5><span>Representing knowledge as dynamic systems</span></h5></a>
                            <a href="#data"><h5>Do you speak data?</h5></a>
                            <a href="#mitigation"><h5><span>Mitigating fallible behaviours in the process of science</span></h5></a>
                            <a href="#future"><h5><span>Looking to the future</span></h5></a>
                            <a href="#weather"><h5>The weather - a current example?</h5></a>
                            <a href="#conclusion"><h5>Conclusion</h5></a>
                        </div>
                    </div>
                    <div className="essay-center-column">
                        <IntroSection />
                        <IncomprehensibilitySection />
                        <TacitKnowledgeSection />
                        <DynamicSystemsSection />
                        <FutureSection />
                    </div>
                </div>
            </div>
        )
    }
}

export default EssayPage;