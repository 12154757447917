const jsonator = (data) => {

    let output = [];
    let wordCounter = 0;

    data.map(word => {

        wordCounter = wordCounter + 1;

        let wordObject = {
            content: ""
        }

        wordObject.id = wordCounter;
        wordObject.content = word[0];
        wordObject.start = word[1];
        wordObject.end = word[2];
        wordObject.duration = word[2] - word[1];

        output.push(wordObject);
    });

    return output;

}

export default jsonator;