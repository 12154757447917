import React, { Component } from 'react';
import ReactPlayer from 'react-player/lazy';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    withRouter
} from "react-router-dom";
import rightArrow from '../img/right-arrow.svg';
import birds from '../lotties/birds.json';
import TextLink from './textLink.js'

class Home extends Component {
    
    render() {
        return(
            <div className="width-vw-100 main-background clearfix">
                <div className="align-top home-container">
                    <div className="about-me">
                        <h2>Oliver Hunt</h2>
                        <p className="column-heading">Interested in building tools to improve our ability to think, learn and create. Currently based in London, UK.</p>
                        <p className="column-heading">Previously cofounder at <TextLink url="https://stairwaylearning.com" text="Stairway"></TextLink>, researcher at <TextLink url="https://imperial.ac.uk" text="Imperial"></TextLink> and BD at <TextLink url="https://joinef.com" text="Entrepreneur First"></TextLink>. </p>
                        <p className="column-heading">If you'd like to reach out, then feel free to contact me <TextLink url="mailto:oh@olly.so" text="here"></TextLink>.</p>
                        <p className="column-heading">I occasionally write things, a couple of which are below.</p>
                        <br />
                        <div className="project" style={{ marginBottom: "24px" }}>
                                <Link to="/internet-cities" >   
                                    <div className="flex-row text-link">
                                        <p className="">Navigating internet cities</p>
                                        <img src={rightArrow} className="right-arrow"/>
                                    </div>
                                </Link>
                                <p className="sub-text">Finding answers to open-ended questions on the internet</p>
                        </div>
                        <div className="project">
                                <Link to="/interface" >   
                                    <div className="flex-row text-link">
                                        <p className="">Building a 21st Century interface for science</p>
                                        <img src={rightArrow} className="right-arrow"/>
                                    </div>
                                </Link>
                                <p className="sub-text">A critical look at scientific literature and what comes next</p>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default Home;

