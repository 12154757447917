import React, { Component } from 'react';
import fig5 from '../img/interfaceEssay/fig5.gif'
import fig6 from '../img/interfaceEssay/fig6.gif'
import fig3 from '../img/interfaceEssay/fig3.png'
import fig4 from '../img/interfaceEssay/fig4.png'
import TextLink from './textLink.js';
import CaptionTextLink from './captionTextLink.js';
import Fig from './Fig.js';
import Callout1 from './Callout1.js'
import Callout2 from './Callout2.js'

class IncomprehensibilitySection extends Component {
    render() {
        return(
            <div>
                <a name="incomprehensibility"><h2>The incomprehensibility of academia</h2></a>
                <br />
                <p>The most important function of scientific literature is to communicate new findings in a shared place, so that individuals can learn from the work of other people. You have likely heard the phrase "standing on the shoulders of giants", and this is an apt metaphor. Each new finding is like a building block that can be used to build other, more elaborate ideas. By sharing building blocks, everyone benefits from our complete collective understanding when solving new problems.</p>
                <br />
                <br />
                <p>Knowledge grows like species evolve. Ideas are combined to form new knowledge in a process we can call <TextLink url="https://www.amazon.co.uk/Act-Creation-Arthur-Koestler-ebook/dp/B08L4CTRT2/ref=sr_1_1?dchild=1&keywords=the+act+of+creation&qid=1629136328&sr=8-1" text="bisociation" />, such that new knowledge is really just a combination of existing ideas. Some of this new knowledge is useful, and is retained, while some of it is not useful, and is forgotten. Therefore, each new idea provides us with the possibility of generating useful new knowledge. By contributing new ideas into a public place, we maximise the possibility of recombination to form useful new knowledge. This is advantageous to the individual, but also to the collective whole. So, there is an evolutionary advantage to making new knowledge accessible in a form that everyone can see and understand.</p>
                <br />
                <br />
                <p>Public scientific knowledge isn't just useful for the production of incremental knowledge, though. While many areas of science are not directly applicable in everyday life, there are many that are applicable in daily life. Science is a public good, especially in domains which are of high immediate public value. Nobody owns it, and everyone should be able to leverage what we know to apply it for their own purposes. </p>
                <br />
                <br />
                <p>However, public goods are not public goods if the public cannot understand them. In nearly all domains, individual scientific papers are highly specialist artifacts that are primarily created for the benefit of exclusive communities of experts, despite being placed in the public domain. This prevents individuals from understanding new ideas that are relevant in their daily life, and prevents society from progressing through widespread understanding of important new ideas.</p>
                <br />
                <br />
                <p>So, this brings us to our topic: the problem is with the <strong>interface</strong> of scientific literature. We cannot expect the wider population to be scientifically literate if they are not given the capability of understanding the artifacts we use to communicate about new scientific developments. </p>
                <br />
                <br />
                <p>This is a thorny problem. Scientific domains are increasingly complex, building upon ever larger pools of assumed knowledge. To work at the top of a field, you must by definition work on problems that are only barely understood by just a few people in the world. Building media that can communicate highly complex ideas with people of many experience levels is a significant challenge. I'll now spend a little time describing some specific features which inhibit this capability as it currently stands.</p>
                <br />
                <br />
                <a name="synthesis"><h3>Knowledge arises from synthesis of many materials</h3></a>
                <br />
                <p>The analogy of building blocks is physically manifested in a scientific paper. It is constructed from ideas derived from previous work, either in the form of citations or vocabulary that has evolved from it. In order to fully understand the paper, the reader must be able to first understand each of these building blocks, and put them together to parse the argument of the paper. </p>
                <br />
                <br />
                <p>So, to understand a given paper in detail, the reader must be able to understand the context of the works it cites. While this does not mean to exhaustively read through every cited paper, each of those papers does represent at least a small building block of the current paper. If the results of the cited work are invalid in some way, then it may well invalidate some or all of the current paper. For this reason, it's important to be able to put the current paper in the context of the papers it cites.</p>
                <br />
                <br />
                <p>Aside from citations, fields of knowledge progressively develop new language to label concepts for easy reference. Each new piece of vocabulary is itself composed of a series of building blocks. We abstract a set of building blocks (a "concept") to form a single new building block (a word or phrase), which we can use to produce new, even larger structures (new concepts) more easily. However, the simplicity of a word hides the complexity of the concept it labels. "Glycosylation" is just a 13-character word, but it describes a complex process that incorporates many layers of Chemistry and Biology. The more you interrogate this word, the more lies beneath the surface. For a novice, this word is a contextual barrier to deeply understanding any article which uses it.</p>
                <br />
                <br />
                <p>This indicates that real scientific understanding arises from <strong>synthesising</strong> a wide range of literature, because synthesis is the mechanism that provides the context which makes a paper understandable. Much like the building blocks in a paper, synthesis provides the building blocks that individuals can assemble into an overall understanding. Given this fact, the interface should be primarily responsible for enabling efficient synthesis, because its role is to communicate and help readers come to insight. </p>
                <br />
                <br />
                <p>However, the interface is very unforgiving in facilitating the process of synthesis. For a given citation, one can usually see a link to the cited paper (which may or may not be viewable under access constraints), and a description of the reason for the citation (usually embedded within the citing paragraph). However, it's rarely possible to see the precise point within the cited article that is relevant for the citation. In order to understand the context, the reader must instead follow the link, and then parse the entire paper to understand it in context, despite the fact that the current paper may only reference a particular claim or result. This creates a considerable amount of unnecessary work for verifying a single claim, and quickly becomes exponential when you consider that the paper may cite 50 other papers, which each cite 50 other papers, and so on. </p>
                <br />
                <br />
                <p>Similarly, any vocabulary that is not already known thrusts the reader out of context. The reader can't 'look up' a term in context, and instead must leave the article and follow Alice down a Google rabbit hole. In succession, many pieces of new vocabulary represent an uphill struggle against working memory. After a few in a row, the article might as well be written in Swahili.</p>
                <br />
                <br />
                <p>It is a challenging and time-consuming process for someone at the top of their field, let alone a relative novice. Vannevar Bush <TextLink url="https://www.theatlantic.com/magazine/archive/1945/07/as-we-may-think/303881/" text="observed" /> <i>as far back as</i> 1945 that keeping up with the literature is practically a full-time job in itself, and we do <i>a lot</i> more science now than we did then. The reality is that the novice has almost no chance of being able to understand primary scientific literature, because they lack the necessary context. And this is only part of the requirement: there are a lot of bad research papers, so experience is required to not only understand the paper, but understand whether or not it is a paper that can be relied upon.</p>
                <br />
                <br />
                <Callout1 />
                <br />
                <br />
                <a name="media"><h3>Passing the buck to mainstream media</h3></a>
                <br />
                <p>Now, given that the majority of people cannot interface with primary literature, it passes the responsibility of scientific communication onto mainstream media. However, the problem here is broadly the same. Most journalists are not scientifically trained, and are subject to the same difficulties in understanding primary literature as their readers. It's definitely not true in all cases, but there are <strong>regular</strong> mistakes in mainstream scientific communication. Again, I don't blame journalists here, because I think the root problem lies with scientific literature. Additionally, even if journalists were trained, media business models are not aligned with telling the objective scientific truth. It does <i>not</i> make sense for a disconnected third party to interpret and report on new scientific developments.</p>
                <br />
                <br />
                <p>This isn't where the issue ends, though. For reasons beyond the scope of this article, there is a strong trend of decreasing trust in longstanding institutions, and mainstream media is a key example. In the case of media, it would appear that this (rightly, in many cases) causes people to distrust the information that it produces, regardless of whether or not it is accurately reported. </p>
                <br />
                <br />
                <p>This brings us to the crux of the problem. Since the scientific interface is not capable of serving the general population, people have to <i>blind trust</i> the institutions who communicate science publicly. When that trust evaporates, people begin to <i>reject the information itself</i>. This puts individuals at a disadvantage, because they are not able to leverage collective knowledge. It puts society as a whole at a disadvantage, because it brings us down to the lowest common denominator.</p>
                <br />
                <br />
                <Callout2 />
                <br />
                <br />
                <p>Incidentally, I think we are seeing this play out at scale in the current global conversation around COVID-19 vaccines. We've all been affected by COVID, almost regardless of where in the world we're located. There is an unprecedented need to eradicate the disease and return to 'normal', and unsurprisingly, a push towards deploying vaccines to as many people as possible. Recently, however, there has been an increasingly vicious debate around the safety and efficacy of vaccines. In many cases that I've seen, it feels more rooted in the tension between personal liberty and government intervention than a rational discussion about the science. </p>
                <br />
                <br />
                <p>My point here is not to pick a side of the argument, but to observe that many of the people on <strong>both</strong> sides of this argument are poorly informed, and generate viewpoints that are aligned with a political disposition rather than rationality. It is an argument about <i>institutions</i>, not about the actual <i>information</i>. This is particularly damaging in the case of vaccines, because they are a game of majority - it only really works if most people get one.</p>
                <br />
                <br />
                <p><i>One</i> lens to look at this through is the interface of science, the medium in which the clinical trial data of all therapeutic interventions is reported and the rest of the world learns of its implications. This interface partially causes the problem, because it does not provide the affordance for people across the spectrum to easily understand findings that are relevant to them. It therefore leaves its wider communication open to misinterpretation, narrative recasting and manipulation, rather than focusing on the core facts. To me, this is the first indication that a seemingly innocuous interface is actually responsible for some critical downstream consequences.</p>
                <ul>
                    <li><p>Scientific papers are highly disconnected, despite the knowledge they contain being very connected. </p></li>
                    <li><p>So, understanding science is challenging and time-consuming, because the reader retains the responsibility of reading lots of papers and making the connections. </p></li>
                    <li><p>This prevents novice readers from deeply engaging with new scientific ideas, which leads to poor scientific literacy.</p></li>
                    <li><p>Ultimately, it leads to damaging effects because most people can't independently verify information that they are told.</p></li>
                    <li><p>Notably, experts are used to this process, but it's still a challenge for them to keep up with the sheer volume of new papers.</p></li>
                </ul>
                <br />
                <br />
           </div>
        )
    }
}

export default IncomprehensibilitySection;