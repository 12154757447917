import React, { Component } from 'react';
import classnames from 'classnames';

class NarrationWord extends Component {

    state = {
        isRead: false,
        isReset: false
    }

    resetWordState = () => {
        this.setState({
            isRead: false,
            isReset: true
        })
    }

    render() {
        const { seekToClickedWord, currentWordID, played, data: { id, content, start, end, duration } } = this.props;
        const animationDuration = {
            animationDuration: `${duration}s`
        }
        if (content) {
            return(
                <button style={animationDuration} className={`a-narrationWord ${((played >= start && played < end) || currentWordID === id) ? "animation-running" : ""}`} onClick={() => seekToClickedWord(start)} >
                    <span >
                        {content + " "}
                    </span>
                </button>
            )
        }
        return null;
    }
    
}

export default NarrationWord