import React, { Component, createRef } from 'react';
import NarrationWord from './NarrationWord.js';
import classnames from "classnames";

class NarrationParagraph extends Component {

    state = {
        hasPlayed: false,
        isPaused: false,
        resetState: false
    }

    narrationWordRef: { current: any } = createRef();

    startNarration = () => {
        this.setState({
            hasPlayed: true,
            resetState: false
        })
    }

    pauseNarration = () => {
        this.setState({
            isPaused: true
        })
    }

    resumeNarration = () => {
        this.setState({
            isPaused: false
        })
    }

    resetNarration = (replay?: boolean) => {

        this.setState({
            hasPlayed: false,
            isPaused: false,
            resetState: true
        })

        setTimeout(() => {
            this.startNarration();
        })

    }

    render() {
        const { hasPlayed, isPaused, resetState, startTimes } = this.state;
        const { data, seekToClickedWord, played, currentWordID } = this.props;

        return(
            <div className="m-narration">
                {data && (
                    <p className="paragraph">
                        {data.map((word) => (
                            <NarrationWord
                                data={word}
                                resetState={resetState}
                                key={word.id}
                                seekToClickedWord={seekToClickedWord}
                                currentWordID={currentWordID}
                                startTimes={startTimes}
                                played={played}
                            />
                        ))}
                    </p>
                )}
            </div>
        )
    }

}

export default NarrationParagraph;