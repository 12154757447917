import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CaptionTextLink extends Component {
    
    render() {
        const { text, url } = this.props;
        return(
            <div className="inline-text-link">
                <a href={url} target="_blank">
                    <div >
                        <h6 className="">{text}</h6>
                    </div>
                </a>  
            </div>
        )
    }
}

export default CaptionTextLink;