import React, { Component } from 'react';
import TextLink from './textLink.js'

class Callout2 extends Component {
    
    render() {
        return(
            <div className="callout-container">
                <p>The 'anti-vax' movement is an interesting example here (<strong>at this point, I'm specifically not talking about COVID-19 vaccines</strong>). It seems as obviously non-sensical as the 'flat-Earth' movement, mostly deriving from a <TextLink url="https://pubmed.ncbi.nlm.nih.gov/9500320/" text="single redacted paper"></TextLink> connecting the MMR vaccine and autism. Despite this, it is an oddly persistent meme that not only causes hesitation among many parents, but it still prevents some parents from vaccinating their children at all. </p>
                <br />
                <br />
                <p>One explanation is that the internet makes it easier for idiocy to find company. However, another explanation is more relevant to our current thread of analysis. The scientific basis of vaccine efficacy is a set of non-trivial concepts, spanning biology, chemistry and statistics. Therefore, to trust vaccines, one of two things needs to be true: either a) you need to understand the science, or b) you need to trust that the scientists and/or the government can make the decision for you.</p>
                <br />
                <br />
                <p>Most people don't understand the science, and cannot use the primary literature to learn about it. This means that they must trust the institutions. It seems as though trust in institutions is waning, which leaves people rejecting the information itself. Could this be an explanation?</p>
            </div>
        )
    }
}

export default Callout2;