import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Quote extends Component {
    
    render() {
        const { text, source } = this.props;
        return(
            <div className="quote-container">
                <p>{text}</p>
                <br />
                <br />
                <p><i>{source}</i></p>
            </div>
        )
    }
}

export default Quote;